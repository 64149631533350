// For call translator : this.$store.getters['translator/translate'](data)
const state = {
    'recorded': 'Déclaration de sinistre enregistrée',
	'supported' : 'Demande prise en charge',
	'in progress' : 'Instructions en cours',
	'regulation' : 'Règlement en cours',
	'closed' : 'Dossier clos',
	'cracks' : 'Fissures',
	'infiltrations' : 'Infiltrations',
	'other' : 'Autres',
	'date_created': 'Date de création',
	'updatedAt': 'Date de mise à jour',
	'gestionnaire': 'Gestionnaire',
	'cat_Aall' : 'Tous',
	'cat_Bactive' : 'Actifs',
	'cat_Creglement' : 'En cours de réglement',
	'cat_Dmessage' : 'Messages',
	'cat_Eclosed' : 'Clôturés',
	'garantees' : 'Code'
};
const getters = {
	translate(state){
		return function (args) {
			return state[args] ? state[args] : args;
		};    
	}
}
export default{
    namespaced: true,
    state,
    getters,
}