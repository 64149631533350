import $ from 'jquery';
/*----- MOBILE_MENU_START -----*/
$(".mobile-menu-icon-aedecp").click(function() {
	$(".menu-toggle-btn-aedecp").toggleClass("open");
	$("body").toggleClass("sidebar-opem-mob-aedecp");
	$("body").toggleClass("hide-scroll");
});
/*----- MOBILE_MENU_END -----*/

/*------- SCROLL_START -------*/
$(".optiscroll").optiscroll({ forceScrollbars: true })
/*------- SCROLL_END -------*/



/*----- MOBILE_MENU_MANAGER_START -----*/
$(".mobile-menu-icon-dbp").click(function() {
	$(".menu-toggle-btn-hp").toggleClass("open");
	$("body").toggleClass("sidebar-opem-mob-dbp");
	$('body').addClass("reduce-sidebar-mob-dbp");
	//$("body").toggleClass("hide-scroll");
});
/*----- MOBILE_MENU_MANAGER_END -----*/

/*----- SIDEBAR_REDUCE_START -----*/
$("#reduce_btn").click(function() {
	$("body").toggleClass("reduce-sidebar-dbp");
	var widthWindow = $(window).width();
	if(widthWindow <= 1439){
		$("body").removeClass("reduce-sidebar-dbp");
		$('body').toggleClass("reduce-sidebar-mob-dbp");	
	}
});

var widthWindow = $(window).width();
if(widthWindow <= 1439){
	$('body').addClass("reduce-sidebar-mob-dbp");
}
$(window).resize(function(){
	var widthWindow = $(window).width();
	if(widthWindow <= 1439){
		$('body').addClass("reduce-sidebar-mob-dbp");
	}
})
/*----- SIDEBAR_REDUCE_END -----*/

/*----- SEARCH_BAR_START -----*/
$("#search_icon_dbp").click(function() {
	$(".tbar-search-main-dbp").toggleClass("search-bar-open-dbp");
});

$(document).mouseup(function(e) 
{
	var container = $(".tbar-search-main-dbp");
	// if the target of the click isn't the container nor a descendant of the container
	if (!container.is(e.target) && container.has(e.target).length === 0) 
	{
		//container.hide();
		//$('.megamenu', this).not('.in .megamenu').stop(true,true).slideUp("600");
		$(".tbar-search-main-dbp").removeClass("search-bar-open-dbp");
	}
});
/*----- SEARCH_BAR_END -----*/

/*------- FILTER_BTN_START -------*/
$("#openFilter").click(function(){
	$(this).toggleClass("open");	
	$(".filter-dropdown-main-lip").slideToggle(500);
	$(".filter-dropdown-main-lip").toggleClass("closed");	
})
/*------- FILTER_BTN_END -------*/

/*------- FILTER_BTN_START -------*/
$(".board-dmenu-dbp .dropdown-item").click(function(){
	var menuHtml = $(this).html();
	$(".board-dmenu-dbp .dropdown-toggle").html(menuHtml);
})
/*------- FILTER_BTN_END -------*/

$("#openAddUser").click(function(){
    $(".rcd-overlay-rcp").fadeToggle();
    $("#add_user_up").toggleClass("open");
})

$("#openEditUser").click(function(){
    $(".rcd-overlay-rcp").fadeToggle();
    $("#edit_user_up").toggleClass("open");
})

$(".rcd-overlay-rcp, .rcd-close-rcp").click(function(){
    $(".rcd-overlay-rcp").fadeOut();
    $("#add_user_up").removeClass("open");
    $("#edit_user_up").removeClass("open");
})

setTimeout(function(){ 
    $(".green-box-main-dp").fadeOut();
}, 3000);

