import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import "@/css/bootstrap.min.css";
import "@/css/stylesheet.css";
import "@/css/responsive.css";
import '@/css/datepicker.css';
import '@/css/daterangepicker.css';
import '@/css/menu_styles.css';
import '@/css/optiscroll.css';
import '@/css/select2.css';
import '@/css/stylesheet_manager.css';
import '@/css/responsive_manager.css';
import '@/css/fonts.css';
import '@/css/fontawesome.min.css';
import '@/css/jquery.dataTables.min.css';
import '@/css/daterangepicker.css';
import '@/js/html5shiv.min.js';
import '@/js/bootstrap.min.js';
import '@/js/optiscroll.js';
import '@/js/menu_script.js'
import '@/js/custom_js.js';
import "@/js/select2.js";

import "@/js/datepicker.js"
import "@/js/bootstrap-datepicker.fr.min.js"
import "@/js/moment.min.js"
import "@/js/daterangepicker.min.js"

import moment from 'moment';
import InstantSearch from 'vue-instantsearch/vue3/es';

moment.locale('fr')

createApp(App).use(InstantSearch).use(router).use(store).mount('#app');

