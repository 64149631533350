import server from "../../api/server";
import vuecookie from 'vue-cookie';
const state = {
    contract_number : null,
    files : null,
    fileToUploadNumber : 0,
    dataFile: null,
    fileToUpload : null,
    iban : null
};
const getters = {

}
const actions = {
    async find({commit}){
        const token = localStorage.getItem('jwt');
        return new Promise((resolve, reject) => {
            server.post('/sinister/files/find', {token})
            .then((res) => {
				resolve({id : res.data.idSinister, nbFiles : res.data.files.length})
                commit('UPDATE_CONTRACT', res.data.contract_number);
                commit('UPDATE_FILES', res.data.files);
                commit('UPDATE_NUMBER_FILES', res.data.fileToUploadNumber);
                commit('UPDATE_UPLOAD_FILES', res.data.fileToUpload);
                commit('UPDATE_IBAN', res.data.iban);
            })
            .catch(err => reject(err))
        });
    },
    async upload({commit}, file){
        const token = localStorage.getItem('jwt');
        commit('UPDATE_DATA_FILE', file.file);
        console.log(file.file)
        return new Promise((resolve, reject) => {
            server.post('/sinister/files/upload', {token, file : file.file})
            .then(() => {
                resolve(true);
            })
            .catch(err => reject(err))
        });
    },
    async download({commit}, file){
        const token = localStorage.getItem('jwt') ? localStorage.getItem('jwt') : vuecookie.get('jwt');
        commit('UPDATE_DATA_FILE', file.file);
        return new Promise((resolve, reject) => {
            server.post('/sinister/files/download', {token, file})
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
    async link({commit}, file){
        const token = localStorage.getItem('jwt') ? localStorage.getItem('jwt') : vuecookie.get('jwt');
        commit('UPDATE_DATA_FILE', file.file);
        return new Promise((resolve, reject) => {
            server.post('/sinister/files/link', { token, file: file.path_attachment, sinisterId: file.id_sinister })
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    }
};
const mutations = {
    UPDATE_CONTRACT(state,data){
        state.contract_number = data;
    },
    UPDATE_FILES(state,data){
        console.log(data)
        // data.forEach((element,index) => {
        //     if(element.type_attachment === 'bank'){
        //         const indexTab = data.indexOf(index);
        //         data.splice(indexTab, 1);
        //     }
        // });
        state.files = data;
    },
    UPDATE_NUMBER_FILES(state,data){
        state.fileToUploadNumber = data;
    },
    UPDATE_DATA_FILE(state,data){
        state.dataFile = data;
    },
    UPDATE_UPLOAD_FILES(state,data){
        state.fileToUpload = data;
    },
    UPDATE_IBAN(state,data){
        state.iban = data;
    },
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}