import server from '../../api/server';
const state = {
    contract_number : null,
    contract_id: null,
    dossier_id: null,
    produit: null
};
const getters = {
    getContractNumber: state => {
        return state.contract_number;
    },
    getContractID: state => {
        return state.contract_id;
    },
    getDossierId: state => {
        return state.dossier_id;
    },
    getProduit: state => {
        return state.produit;
    }
}
const actions = {
    async findContract({commit, state} ,contract_number){
        return new Promise((resolve, reject) => {
            server.post('contract/find', {contract_number})
            .then(res => {
                commit('UPDATE_CONTRACT', contract_number);
                if(res.status === 200){
                    state.contract_id = res.data.contract_id;
                    state.dossier_id = res.data.dossier_id
                    state.produit = res.data.produit
                    localStorage.setItem('contract_number', contract_number);
                    localStorage.setItem('contract', res.data.contract_id);
                    resolve(true)
                }
            })
            .catch(() => {
                reject(false);
            })
        });
    }
};
const mutations = {
    UPDATE_CONTRACT(state, contract_number){
        state.contract_number = contract_number;
    }
};

export default{
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}