import server from '../../api/server';

const state = {
    company: localStorage.getItem('company') || "QBE"
};
const getters = {
    getCompany: state => {
        return state.company;
    }
}
const actions = {
    async find(){
        console.log('toto');
        return new Promise((resolve, reject) => {
            server.get('/administrator/dashboard', { params: { company: state.company } })
            .then((res) =>{
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            })
        });
    },
    updateCompany({commit}, data) {
        commit('UPDATE_COMPANY', data);
        localStorage.setItem('company', data);
    }
};
const mutations = {
    UPDATE_COMPANY(state, data){
        state.company = data;
    },
};

export default{
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}